import React, { useState, useEffect } from 'react';
import styles from './AdminPersonalDetails.module.css';
import profileimg from 'project-name/assets/img/admin/profile/guy-plaid-shirt.jpg';
import { Link } from 'react-router-dom';
import users  from "project-name/assets/users.png";
import { CiEdit } from 'react-icons/ci';
import { Button, TextField, IconButton, InputAdornment } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from 'project-name/Redux/Store';
import { fetchAdminProfile } from 'project-name/Redux/features/adminProfileSlice'; // Assume updateAdminProfile is the action to update
import { updateCandidateDetails } from 'project-name/Redux/features/updateCandidateSlice';
import ForgetPasswordPage from 'project-name/pages/AdminPages/ForgetPasswordPage';
const AdminPersonalDetails: React.FC = () => {
  const [isEditMode, setIsEditMode] = useState(false); // State to control edit mode
  const profileDetail = useSelector((state: RootState) => state.adminProfile.profile?.[0] || {});
  const [formData, setFormData] = useState(profileDetail); // State to hold form data

  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAdminProfile());
  }, [dispatch]);

  useEffect(() => {
    // Update formData when profileDetail changes
    setFormData(profileDetail);
  }, [profileDetail]);

  const handleEditClick = () => {
    setIsEditMode(true); // Enable edit mode
  };

  const handleUpdateClick = () => {
    // Handle the API call to update the profile
    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      formDataToSend.append(key, value as string);
    });

    dispatch(updateCandidateDetails(formData)); // Dispatch the update action with the form data
    setIsEditMode(false); // Disable edit mode after update
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState:any) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className={styles.mainDiv}>
      <div className={styles.personalDiv}>
        <h4 className={styles.head4}>Personal details</h4>
        <div className={styles.imgFormDiv}>
          <div className={styles.imgFormDiv1}>
            <img src={users} alt="Profile" />
          </div>
          <form action="">
            <div className={styles.formDiv}>
              <div className={styles.formInput1}>
                <div className={styles.formGroup}>
                  <label htmlFor="first-name">First name</label>
                  <TextField
                    type="text"
                    id="first-name"
                    name="first_name"
                    className={styles.formControl}
                    value={formData.first_name || ''}
                    onChange={handleChange} // Update the form data
                    disabled={!isEditMode} // Disable field if not in edit mode
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleEditClick} aria-label="edit first name">
                            <CiEdit style={{ color: '#000' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="email">Personal Email</label>
                  <TextField
                    type="email"
                    id="email"
                    name="email"
                    className={styles.formControl}
                    value={formData.email || ''}
                    onChange={handleChange} // Update the form data
                    disabled={!isEditMode}
                  />
                </div>
              </div>

              <div className={styles.formInput1}>
                <div className={styles.formGroup}>
                  <label htmlFor="last-name">Last name</label>
                  <TextField
                    type="text"
                    id="last-name"
                    name="last_name"
                    className={styles.formControl}
                    value={formData.last_name || ''}
                    onChange={handleChange} // Update the form data
                    disabled={!isEditMode}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleEditClick} aria-label="edit last name">
                            <CiEdit style={{ color: '#000' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="contact-number">Contact number</label>
                  <TextField
                    type="phone"
                    id="contact-number"
                    name="contact_number"
                    className={styles.formControl}
                    value={formData.contact_number || ''}
                    onChange={handleChange} // Update the form data
                    disabled={!isEditMode}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleEditClick} aria-label="edit contact number">
                            <CiEdit style={{ color: '#000' }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div>
        <div className={styles.btn}>
          {!isEditMode ? (
            <Button type="button" variant="contained" color="primary" onClick={handleEditClick}>
              Edit Candidate
            </Button>
          ) : (
            <Button type="button" variant="contained" color="primary" onClick={handleUpdateClick}>
              Update
            </Button>
          )}
        <ForgetPasswordPage/>
        </div>
      </div>
    </div>
  );
};

export default AdminPersonalDetails;
