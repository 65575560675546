import React, { useEffect, useState } from 'react';
import styles from './adminProgress.module.css';
import profileImg from 'project-name/assets/img/admin/profile/guy-plaid-shirt.jpg';
// import { profileData, ticketRaisedData } from '../../../../Data/admin/adminProgress';
import { useSelector } from 'react-redux';
import { RootState } from 'project-name/Redux/Store';
import { fetchProfileDetails } from 'project-name/Redux/features/candidateDetailsSlice';
import { useDispatch } from 'react-redux';

import { fetchTicketsRaised } from 'project-name/Redux/features/adminTicketSlice';
import {
  AppDispatch
} from 'project-name/Redux/Store';


// Import SVG icons
import ProfileIcon from 'project-name/assets/img/svg/profile.svg';
import FileIcon from 'project-name/assets/img/svg/file.svg';
import CheckIcon from 'project-name/assets/img/svg/check.svg';
import AlertIcon from 'project-name/assets/img/svg/check.svg';
import { Link } from 'react-router-dom';
import { AuthContext } from 'project-name/components/AuthContext/AuthContext';
import { TicketModal } from '../Global/TicketSection/TicketTable';
import users  from "../../../assets/users.png"


interface ProfileDataItem {
  id: number | null | undefined;
  value: string;
  icon: string;
  label: string;
}
export interface Ticket {
  id: number;
  ticketType: string;
  dateRaised: string;
  supportStatus: string;
  user_id: number;
  profileImgSrc: string;
}

// interface TicketRaisedDataItem {
//   id: number;
//   ticketType: string;
//   dateRaised: string;
//   supportStatus: string;
//   profileImgSrc: string;
// }


const AdminProgress: React.FC = () => {
  const userName = localStorage.getItem("userName");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfileDetails());

  }, [dispatch]);
  useEffect(() => {
    dispatch(fetchTicketsRaised());
  }, [dispatch]);
  const tickets = useSelector((state: RootState) => state.tickets.tickets);

  let profileDetail = useSelector((state: RootState) => state.profile.data)

  const profileData = profileDetail || [];
  const adminProfile = () => {
    <Link to="/edit/admin-profile" />
  }
  const handleOpen = (ticket: Ticket) => {
    console.log("selected ticket",ticket);
    setSelectedTicket(ticket);
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  }
  const getSupportStatusClass = (supportStatus: string) => {
    switch (supportStatus) {
      case 'In process':
        return styles.inProcess;
      case 'Pending':
        return styles.pending;
      case 'Open':
        return styles.open;
      default:
        return ''; // Default class or no class if status doesn't match
    }
  };

  const renderIcon = (icon: string) => {
    switch (icon) {
      case 'profile.svg':
        return <img src={ProfileIcon} alt="Profile Icon" />;
      case 'file.svg':
        return <img src={FileIcon} alt="File Icon" />;
      case 'check.svg':
        return <img src={CheckIcon} alt="Check Icon" />;
      case 'alert.svg':
        return <img src={AlertIcon} alt="Alert Icon" />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.heroSection}>
        <div className={styles.heroSectionLeft}>
          <div className={`${styles.heroSectionBox} ${styles.boxLHS1}`}>
            <img src={users} alt="" onClick={adminProfile}  />
            <div className={styles.boxLHS1WelcomeBox}>
              <h2>Welcome Back, {userName || ""}</h2>
              {/* <p>Lorem ipsum dolor sit amet mque laboriosam quam illum facilis, enim</p> */}
            </div>
          </div>

          <div className={`${styles.heroSectionBox} ${styles.boxLHS2}`}>
            {profileData.map((item: ProfileDataItem, index: number) => (
              <Link to="/candidate" key={item.id}>
                <div className={styles.boxLHS2WorkBox} key={index}>
                  <h1>{item.value}</h1>
                  <div className={styles.boxLHS2WorkBoxTitle}>
                    {/* Render SVG icon dynamically */}
                    {renderIcon(item.icon)}
                    <p>{item.label}</p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <div className={styles.heroSectionRight}>
          <div className={`${styles.heroSectionBox} ${styles.boxRHS3}`}>
            <div className={styles.ticketRaisedHead}>
              <h4>Tickets raised</h4>
              <p id="toggleTickets">
                {/* Open <img src="assets/icons/chevron-down.svg" alt="" /> */}
              </p>
            </div>
            <div className={styles.ticketRaisedListsBox}>
              {tickets.map((ticket, index) => (
                <div onClick={() => handleOpen(ticket)} className={`${styles.ticketRaisedList} ${styles.list1}`} key={index} data-testid="ticket-raised-item">
                  <img src={users} style={{width:"60px"}} alt="" />
                  <div className={styles.listTicketType}>
                    <p className={styles.ticketHead}>Ticket type</p>
                    <p className={styles.ticketPara}>{ticket.ticketType}</p>
                  </div>
                  <div className={styles.listTicketDate}>
                    <p className={styles.ticketHead}>Date Raised</p>
                    <p className={styles.ticketPara}>{new Date(ticket.dateRaised).toLocaleDateString()}</p>

                  </div>
                  <div className={`${styles.listTicketStatus} `}>
                    <p className={styles.ticketHead}>Support status</p>
                    <p className={`${styles.ticketPara} ${getSupportStatusClass(ticket.supportStatus)}`}>{ticket.supportStatus}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* Add other right section content */}
        </div>

      </div>
      {selectedTicket && <TicketModal ticket={selectedTicket} open={isModalOpen} onClose={handleClose} />}
    </>
  );
};

// Function to render SVG icon dynamically based on icon filename
const renderIcon = (icon: string) => {
  switch (icon) {
    case 'profile.svg':
      return <img src={ProfileIcon} alt="Profile Icon" />;
    case 'file.svg':
      return <img src={FileIcon} alt="File Icon" />;
    case 'check.svg':
      return <img src={CheckIcon} alt="Check Icon" />;
    case 'alert.svg':
      return <img src={AlertIcon} alt="Alert Icon" />;
    default:
      return null;
  }
};

export default AdminProgress;
