import Navbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';

import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import FaqSection from 'project-name/components/CandidateSecion/Faq/FaqSection';
import React from 'react'
import Chatbot from './Chatbot';
import styles from "../CandidatePages/chatbot.module.css"
import mainstyles from '../.././css/layout.module.css';

const ChatbotPage = () => {
  return (
    <div className={mainstyles.mDiv}>
      <div>
        <Navbar />
      </div>
      <div className={styles.secondDiv}>

        <div style={{ width: "85%", margin: "auto", padding: "30px 0" }}><Breadcrumb bread1="Home" bread2="Chatbot" link1="/" />
        </div>


        <Chatbot />




      </div>


    </div>
  )
}

export default ChatbotPage
