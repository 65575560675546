import React, { useState, FormEvent } from 'react';
import styles from './Documents.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {toast} from 'react-toastify';

import Modal from '@mui/material/Modal';
import SubmitPopup from 'project-name/components/CandidateSecion/Global/Popup/SubmitPopup';
import FileInput from './GlobalFormsField/FileInput';

import { getStatusByName, handleDownload } from 'project-name/util/util';

import apiClient from 'project-name/api/apiClient';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'project-name/Redux/Store';
import InprogressIcon from 'project-name/components/CandidateSecion/Global/Icons/InprogressIcon';
import CompletedIcon from 'project-name/components/CandidateSecion/Global/Icons/CompletedIcon';
import PhoneMenu from 'project-name/components/CandidateSecion/Global/Navbar/phoneMenu';
import { setPdfData } from 'project-name/Redux/features/Candidate';

interface FileInputData {
  confidentialFile?: File | null;
}

interface FileInputData2 {
  Form11?: File | null;
}

interface FileInputData3 {
  PF?: File | null;
}

interface FileInputData4 {
  offerFile?: File | null;
}

interface FileInputData5 {
  cheersFile?: File | null;
}

interface FileInputData6 {
  selfDeclarationFile?: File | null;
}

interface FileInputData7 {
  profileFile?: File | null;
}

const OtherDetails = () => {
  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [error, setError] = useState('');

  const dispatch = useDispatch<AppDispatch>();
  let pdfData = useSelector((state: RootState) => state.candidate.pdfData);
  const fetchFiles = async () => {

    try {
      const response = await apiClient.get(`/api/get_files`, {
        withCredentials: true,
      });

      if (response.status !== 200) {
        throw new Error(`Failed to fetch candidate documents: ${response.status}`);
      }
      const data = response.data;
      if (data) {
        pdfData = data;
        toast.success('Documents fetched successfully');
        // localStorage.setItem("pdfData", JSON.stringify(data));
        dispatch(setPdfData(data));
      }
    } catch (error) {
// toast.error('Error fetching documents:');
      console.error('Error fetching documents:', error);
    }
  }

  const filteredStatus = getStatusByName("Statutory documents");
  const isPending = filteredStatus === "Pending";


  const storedDocTypeIdsWithStatus105 = JSON.parse(localStorage.getItem("docTypeIdsWithStatus105") || '[]');
  const checkIfAnyDocTypeIdsExist = (storedDocTypeIds: number[]): boolean => {
    return storedDocTypeIdsWithStatus105.some((docTypeId: number) => storedDocTypeIds.includes(docTypeId));
  };

  const cufStatus = checkIfAnyDocTypeIdsExist([314]);
  const sdfStatus = checkIfAnyDocTypeIdsExist([317]);
  const formElStatus = checkIfAnyDocTypeIdsExist([318]);
  const pfStatus = checkIfAnyDocTypeIdsExist([319]);
  
  const cfbStatus = checkIfAnyDocTypeIdsExist([316]);
  const picStatus = checkIfAnyDocTypeIdsExist([322]);


  if (pdfData && Object.keys(pdfData).length === 0) {
    fetchFiles();
  }
  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? -1 : index));
  };
  let OtherCompletedDocuments = useSelector((state: RootState) => state.OtherStatus.documentList);
  if (OtherCompletedDocuments.length === 0) {
    OtherCompletedDocuments = localStorage.getItem('matchingDocuments') ? JSON.parse(localStorage.getItem('matchingDocuments') || '{}') : [];
  }
  // Example form submission handling
  const handleSubmit = async (e: FormEvent, formType: string) => {
    e.preventDefault();

    const formData = new FormData();
    if (formType === 'Confidentiality') {
      
      console.log(ConfidentialityFileInput.confidentialFile);
      if (ConfidentialityFileInput.confidentialFile) {
        formData.append('document', ConfidentialityFileInput.confidentialFile);
        formData.append('document_type', 'c_u_f');
        setError('')
      } else{setError('This field id required')
       
        return false
      }
    } else if (formType === 'Form11') {
      if (form11FileInput.Form11) {
        formData.append('document', form11FileInput.Form11);
        formData.append('document_type', 'form_11');
        setError('')
      }
      else{setError('This field id required')
       
        return false
      }
      
    } else if (formType === 'PF') {
      if (PFFileInput.PF) {
        formData.append('document', PFFileInput.PF);
        formData.append('document_type', 'pf');
        setError('')
      }
      else{setError('This field id required')
       
        return false
      }
    } else if (formType === 'SelfDeclaration') {
      if (selfDeclarationFileInput.selfDeclarationFile) {
        formData.append('document', selfDeclarationFileInput.selfDeclarationFile);
        formData.append('document_type', 's_d_f');
        setError('')
      }
      else{setError('This field id required')
       
        return false
      }
    }
    // else if (formType === 'Offer') {
    //   if (OfferFileInput.offerFile) {
    //     formData.append('document', OfferFileInput.offerFile);
    //     formData.append('document_type', 'offer_letter');
    //   }
    // } 
    else if (formType === 'Cheers') {
      if (CheersFileInput.cheersFile) {
        formData.append('document', CheersFileInput.cheersFile);
        formData.append('document_type', 'cheers');
        setError('')
      }else{setError('This field id required')
       
        return false
      }
    }
    else if (formType === 'profile') {
      if (ProfileFileInput.profileFile) {
        formData.append('document', ProfileFileInput.profileFile);
        formData.append('document_type', 'can_picture');
        setError('')
      }
      else{setError('This field id required')
       
        return false
      }
    }

    // Example POST request
    try {
      const response = await apiClient.post(
        `/api/candidate/upload-document`,
        formData,
        {
          withCredentials: true,
        }
      );

      if (response.status !== 200) {
        throw new Error('File upload failed');
      }

      const result = response.data;
      toast.success('File uploaded successfully');
      console.log('File uploaded successfully:', result);
      setShowModal(true);
    } catch (error) {
      toast.error('File upload failed');
      console.error('Error uploading file:', error);
    }
  };


  const [ConfidentialityFileInput, setConfidentialityFileInput] = useState<FileInputData>({
    confidentialFile: null,
  });

  const [selfDeclarationFileInput, setSelfDeclarationFileInput] = useState<FileInputData6>({
    selfDeclarationFile: null,
  });

  const [form11FileInput, setform11FileInput] = useState<FileInputData2>({
    Form11: null,
  });

  const [PFFileInput, setPFFileInput] = useState<FileInputData3>({
    PF: null,
  });

  const [OfferFileInput, setOfferFileInput] = useState<FileInputData4>({
    offerFile: null,
  });

  const [CheersFileInput, setCheersFileInput] = useState<FileInputData5>({
    cheersFile: null,
  });

  const [ProfileFileInput, setProfileFileInput] = useState<FileInputData7>({
    profileFile: null,
  });

  // Function to handle file input change
  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    const files = target.files;

    // console.log(files)

    if (files && files.length > 0) {
      // Process the files based on input type
      switch (target.id) {
        case 'confidentialityInput':
          setConfidentialityFileInput((prevData) => ({
            ...prevData,
            confidentialFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'form11Input':
          setform11FileInput((prevData) => ({
            ...prevData,
            Form11: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'PFInput':
          setPFFileInput((prevData) => ({
            ...prevData,
            PF: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'offerInput':
          setOfferFileInput((prevData) => ({
            ...prevData,
            offerFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'cheersInput':
          setCheersFileInput((prevData) => ({
            ...prevData,
            cheersFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        case 'selfDeclarationInput':
          setSelfDeclarationFileInput((prevData) => ({
            ...prevData,
            selfDeclarationFile: files[0] || null, // Set to null if no file selected
          }));
        
          break;
        case 'profileInput':
          setProfileFileInput((prevData) => ({
            ...prevData,
            profileFile: files[0] || null, // Set to null if no file selected
          }));
          break;
        default:
          break;
      }
    }
  };


  // submit popup
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const fullName = sessionStorage.getItem("candidateName")

  return (
    <section className={styles.mainAccord}>
      {/* Modal */}
      {showModal && (
        <Modal
          open={showModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <SubmitPopup handleClose={handleCloseModal} />
        </Modal>
      )}
      {/* first accordion  */}
      <div className={` ${styles.subAccord} ${(!cufStatus && !isPending) ? styles.disabled : ''}`}>
        <button
          className={`${styles.accordion} ${activeIndex === 0 ? styles.active : ''}`}
          onClick={() => toggleAccordion(0)}
          aria-expanded={activeIndex === 0}
        >
          <p>Confidentiality Undertaking Form</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'CUF (Confidentiality Undertaking Form)' || doc.doc_type_name === "LOA (Letter Of Acknowledgement)") ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}

            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 0 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 0 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please upload Confidentiality Undertaking form. In case you don't have a current Confidentiality Undertaking Form, then download a template from link below and upload the same after filling the details.</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('auth', pdfData?.LOA?.["Ab_InBev_Letter of Authorization.pdf"] ?? '')}>
                    Download Acknowledgement Form
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
               
                name={`${fullName} 's Confidentiality Undertaking Form`}
                  label="Upload filled Confidentiality Undertaking Form"
                  file={ConfidentialityFileInput.confidentialFile}
                  onChange={handleFileInputChange}
                  id="confidentialityInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
                  {error && <p style={{ color: 'red' , fontSize:"12px" }}>{error}</p>}
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'LOA (Letter Of Acknowledgement)') ? (
                    <Button type="submit" variant="contained" color="secondary" disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'Confidentiality')}>
                      Submit document
                    </Button>
                  )}
                 
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* second accordion  */}
      <div className={` ${styles.subAccord} ${(!sdfStatus && !isPending) ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 1 ? styles.active : ''}`}
          onClick={() => toggleAccordion(1)}
          aria-expanded={activeIndex === 1}
        >
          <p>Self Declaration Form</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'SDF (Self Declaration Form)') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 1 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 1 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please upload your self declaration form. Download the template from below and upload the same after filling in the details.</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('self', pdfData?.LOA?.["Ab_InBev_Letter of Authorization.pdf"] ?? '')}>
                    Self Declaration Form
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
               
                name={`${fullName} 's Self Declaration Form`}
                  label="Upload filled Self Declaration Form"
                  file={selfDeclarationFileInput.selfDeclarationFile}
                  onChange={handleFileInputChange}
                  id="selfDeclarationInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'SDF (Self Declaration Form)') ? (
                    <Button type="submit" variant="contained"  color="secondary" disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" sx={{fontWeight:"600" , padding:"10px 16px"}} onClick={(e) => handleSubmit(e, 'SelfDeclaration')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* third accorion */}
      <div className={` ${styles.subAccord} ${(!formElStatus && !isPending) ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 2 ? styles.active : ''}`}
          onClick={() => toggleAccordion(2)}
          aria-expanded={activeIndex === 2}
        >
          <p>Form 11</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Form 11') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 2 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 2 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Upload filled copy of form 11</p>
                <div className={styles.btnDivack} style={{ display: "flex", gap: "20px" }}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('form11', pdfData?.Form11?.["Form 11.pdf"] ?? '')}>
                    Form 11 template
                  </Button>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('form11inst', pdfData?.Form11Instruction?.["Instruction for filling Form 11.pdf"] ?? '')}>
                    Form 11 filling instruction
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
               
                name={`${fullName} 's Copy of form 11`}
                  label="Upload filled copy of form 11"
                  file={form11FileInput.Form11}
                  onChange={handleFileInputChange}
                  id="form11Input"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Form 11') ? (
                    <Button type="submit" variant="contained" color="secondary"  sx={{fontWeight:"600" , padding:"10px 16px"}} disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" sx={{fontWeight:"600" , padding:"10px 16px"}} color="primary" onClick={(e) => handleSubmit(e, 'Form11')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* fourth accordian */}
      <div className={` ${styles.subAccord} ${(!pfStatus && !isPending) ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 3 ? styles.active : ''}`}
          onClick={() => toggleAccordion(3)}
          aria-expanded={activeIndex === 3}
        >
          <p>PF Non Election Declaration Form</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'PF(Non Election Declaration Form)') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 3 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 3 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please upload your PF non election declaration form. Download the template from below and upload the same after filling in the details</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('pf', pdfData?.PfForm?.["PF Form.pdf"] ?? '')}>
                    PF Non Election Declaration Form
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
                  name={`${fullName} 's Non Election Declaration Form`}
                
                  label="Upload filled copy of PF Non Election Declaration Form"
                  file={PFFileInput.PF}
                  onChange={handleFileInputChange}
                  id="PFInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'PF(Non Election Declaration Form)') ? (
                    <Button type="submit" variant="contained" sx={{fontWeight:"600" , padding:"10px 16px"}} color="secondary" disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" sx={{fontWeight:"600" , padding:"10px 16px"}} color="primary" onClick={(e) => handleSubmit(e, 'PF')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* fifth accordian */}


      {/* sixth accordian */}
      {/* <div className={` ${styles.subAccord} ${(!offerLetterStatus && !isPending) ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 5 ? styles.active : ''}`}
          onClick={() => toggleAccordion(5)}
          aria-expanded={activeIndex === 5}
        >
          <p>Offer letter</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Offer Letter') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 5 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 5 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please upload your signed offer letter shared by your recruiter. Please make sure that you upload a clear photo of your signature.</p>
              </div>
              <div>


                <FileInput
                  label="Upload Signed copy of Offer Letter"
                  file={OfferFileInput.offerFile}
                  onChange={handleFileInputChange}
                  id="offerInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Offer Letter') ? (
                    <Button type="submit" variant="contained" color="secondary" disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" onClick={(e) => handleSubmit(e, 'OfferLetter')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div> */}

      {/* seventh accordian */}
      <div className={` ${styles.subAccord} ${(!cfbStatus && !isPending) ? styles.disabled : ''}`}>

        <button
          className={`${styles.accordion} ${activeIndex === 6 ? styles.active : ''}`}
          onClick={() => toggleAccordion(6)}
          aria-expanded={activeIndex === 6}
        >
          <p>Cheers 4 Beers - Indemnity Form</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'CFB(Cheers 4 Beers)') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 6 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 6 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please click here to download the Cheers 4 Beers - Idemnity. Kindly upload a duly signed copy below.</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('cheers', pdfData?.C4B?.["Cheers 4 Beers - Indemnity.pdf"] ?? '')}>
                    Cheers 4 Beers - Idemnity
                  </Button>
                </div>
              </div>
              <div>


                <FileInput
               
                name={`${fullName} 's Idemnity Form`}
                
                  label="Upload filled Cheers 4 Beers - Idemnity Form"
                  file={CheersFileInput.cheersFile}
                  onChange={handleFileInputChange}
                  id="cheersInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'CFB(Cheers 4 Beers)') ? (
                    <Button type="submit" variant="contained" sx={{fontWeight:"600" , padding:"10px 16px"}} color="secondary" disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" sx={{fontWeight:"600" , padding:"10px 16px"}} color="primary" onClick={(e) => handleSubmit(e, 'Cheers')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      {/* eight accordian */}
      <div className={` ${styles.subAccord} `}>

        <button
          className={`${styles.accordion} ${activeIndex === 7 ? styles.active : ''}`}
          onClick={() => toggleAccordion(7)}
          aria-expanded={activeIndex === 7}
        >
          <p>Photograph</p>
          <b className={styles.statusDiv}>
            {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Candidate Picture') ? (
              <CompletedIcon />
            ) : (
              <InprogressIcon />
            )}
            <ExpandMoreIcon
              className={styles.moreIcon}
              style={{ transform: activeIndex === 7 ? 'rotate(180deg)' : 'rotate(0deg)', color: '#fff' }}
            />
          </b>
        </button>
        <form className={styles.panel} style={{ display: activeIndex === 7 ? 'block' : 'none' }}>
          <div className={styles.mAttachDiv}>
            <div className={styles.fieldDiv} style={{ display: "flex", flexDirection: "column" }}>
              <div className='form-header'>
                <p>Please click here to download the ID Card Photo Guidelines.</p>
                <div className={styles.btnDivack}>
                  <Button variant="contained" endIcon={<CloudDownloadIcon />} className={styles.btn} onClick={() => handleDownload('profile', pdfData?.ImgGuidelines?.["Image guidelines.pdf"] ?? '')}>
                    ID Card Photo Guidelines
                  </Button>
                </div>
              </div>
              <div className={`${(!picStatus && !isPending) ? styles.disabled : ''}`}>


                <FileInput
                  label="Upload ID Card Photo"
                  name={`${fullName} 's ID Card Photo`}
                 
                  file={ProfileFileInput.profileFile}
                  onChange={handleFileInputChange}
                  id="profileInput"
                  fileCss1="subDiv2"
                  fileCss2="label"
                  fileCss3="fileDiv2"
                  fileCss4="fileDiv1"
                />
              </div>

            </div>
            <div className={styles.btnDiv}>
              <div></div>
              <div className={styles.subbtnDiv}>
                <div className={styles.btn1}>
                  {OtherCompletedDocuments.length > 0 && OtherCompletedDocuments.find((doc) => doc.doc_type_name === 'Candidate Picture') ? (
                    <Button type="submit" variant="contained" color="secondary" sx={{fontWeight:"600" , padding:"10px 16px"}} disabled>
                      Document already submitted
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary" sx={{fontWeight:"600" , padding:"10px 16px"}} onClick={(e) => handleSubmit(e, 'profile')}>
                      Submit document
                    </Button>
                  )}

                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <PhoneMenu />
    </section>
  );
};

export default OtherDetails;