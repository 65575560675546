import React from 'react';
import styles from './CandidateTable.module.css'; // Import your CSS module here
import profilesData from '../../../../Data/admin/candidateData.json'; 
import FilterButton from './FilterButton';
import Pagination from '../Global/RecruiterSection/Pagination';
import CandidateRow from './CandidateRow';
import { paginate } from 'project-name/util/paginationUtils'; // Import the pagination utility function
import { usePagination } from 'project-name/util/tableUtils'; // Import the custom hook
import { RootState } from 'project-name/Redux/Store';
import { useSelector } from 'react-redux';

interface SavedProfile {
  full_name: string;
  tower: string;
  function: string;
  employee_band: string;
  office_location: string;
  bgv_status: string;
  d_o_j: string;
  is_documents_moved: string;
  status_name: string;
  user_id: number;
}

const CandidateTable: React.FC = () => {
  let statusList = useSelector((state: RootState) => state.adminProfile.profile);

  // const savedProfiles: SavedProfile[] = statusList || []; 
  const savedProfiles: SavedProfile[] = statusList || []; 
  
  const itemsPerPage: number = 5; // Number of items to display per page

  // Use custom hook for pagination
  const { currentPage, totalPages, handlePageChange } = usePagination(savedProfiles.length, itemsPerPage);

  return (
    <section>
      <div className={styles.savedProfilesContainer}>
        <div className={styles.savedProfilesHead}>
          <h4>Candidate profiles </h4>
          <div>
            <FilterButton />
          </div>
        </div>
        <div className={styles.savedProfilesListContainer}>
          <div className={styles.savedProfilesListTitle}>
            <p>Name</p>
            <p>Tower</p>
            <p>Function</p>
            <p>Band</p>
            <p>Location</p>
            <p>BGV Status</p>
            <p>Joining Date</p>
            <p>Documents</p>
            <p>Status</p>
           
          </div>
          {paginate(savedProfiles, currentPage, itemsPerPage).map((profile, index) => (
            <CandidateRow key={index} profile={profile} />
            
          ))}
        </div>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </section>
  );
};

export default CandidateTable;
