import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import apiClient from 'project-name/api/apiClient';
import { toast } from 'react-toastify';

interface ChatState {
    response:string | null;
 
  loading: boolean;
  error: string | null;
}

const initialState: ChatState = {
  response: null,
  loading: false,
  error: null,
};

export const fetchChatResponse = createAsyncThunk(
  'chat/fetchChatResponse',
  async (query: string, { rejectWithValue }) => {
    try {
      const response = await apiClient.post('https://upscale-tech.azurewebsites.net/onboarding/chat', {
        query,
        is_cache: 'n',
      });
      return response.data;
    }catch (error: any) {
        return rejectWithValue(error.message || 'An unexpected error occurred');
      }
  }
);

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChatResponse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchChatResponse.fulfilled, (state, action: PayloadAction<string>) => {
        state.loading = false;
        state.response = action.payload;
      })
      .addCase(fetchChatResponse.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload || 'Failed to fetch chat response');
      });
  },
});

export default chatSlice.reducer;