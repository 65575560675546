import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './components/AuthContext/AuthContext';

interface ProtectedRouteProps {
  element: React.ComponentType;
  persona?: 'admin' | 'user' | 'recruiter';
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element: Component, persona }) => {
  const { authenticated } = useContext(AuthContext) as { authenticated: boolean } || { authenticated: null };

  if (authenticated === null) {
    return <div>Loading...</div>;
  }
      const userType = localStorage.getItem('userName'); 
      const isAdmin = userType === 'admin' || userType === 'recruiter';

  if (isAdmin) {
    if (persona === 'user') {
      return <Navigate to="/admin-home" />;
    }
    return <Component />;
  }

  if (persona === 'admin' && !isAdmin) {
    return <Navigate to="/unauthorized" />;
  }

  return authenticated ? <Component /> : <Navigate to="/login" />;
};

export default ProtectedRoute;