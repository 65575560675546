// senseCandidatesSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import apiClient from 'project-name/api/apiClient';
// Import the types

// Initial state for the slice
const initialState: {
    candidates: any[];
    loading: boolean;
    error: string | null;
  } = {
    candidates: [],
    loading: false,
    error: null,  // null is a valid value here
  };

// Thunk to fetch candidates from the API
export const fetchSenseCandidates = createAsyncThunk<
  any, 
  void, 
  { rejectValue: string } 
>('senseCandidates/fetchSenseCandidates', async (_, { rejectWithValue }) => {
  try {
    const response = await apiClient.get('/api/admin/get_sense_candidates', {
        withCredentials: true,
    });
    return response.data;
  } catch (error: any) {
    // Reject with a simple string error message
    return rejectWithValue(error.response?.data?.message || 'Failed to fetch candidates');
  }
});

// Create the slice
const senseCandidatesSlice = createSlice({
  name: 'senseCandidates',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSenseCandidates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSenseCandidates.fulfilled, (state, action: PayloadAction<any>) => {
        state.candidates = action.payload.candidates || action.payload; // Use payload directly if needed
        state.loading = false;
        toast.success('Candidates fetched successfully');
      })
      .addCase(fetchSenseCandidates.rejected, (state, action: PayloadAction<string | undefined>) => {
        state.loading = false;
        state.error = action.payload || 'An unknown error occurred';
        toast.error(state.error);
      });
  },
});

export default senseCandidatesSlice.reducer;
