import React from 'react';
import styles from './RecruiterTable.module.css'; // Import your CSS module here
import profilesData from '../../../../../Data/admin/recruiterData.json'; // Import your JSON data file here
import ProfileRow from './ProfileRow';
import Pagination from './Pagination';
import { paginate } from 'project-name/util/paginationUtils'; // Import the pagination utility function
import { usePagination } from 'project-name/util/tableUtils'; // Import the custom hook

interface SavedProfile {
  name: string;
  email: string;
  candidates: string;
  recruiterrole: string[];
}


interface RecruiterTableProps {
  headers: string[];
}

const RecruiterTable: React.FC<RecruiterTableProps> = ({ headers }) => {

  const savedProfiles: SavedProfile[] = profilesData;
  const itemsPerPage: number = 5; // Number of items to display per page

  // Use custom hook for pagination
  const { currentPage, totalPages, handlePageChange } = usePagination(savedProfiles.length, itemsPerPage);

  return (
    <section>
      <div className={styles.savedProfilesContainer}>
        <div className={styles.savedProfilesListContainer}>
          <div className={styles.savedProfilesListTitle}>
          {headers.map((header, index) => (
              <h4 key={index} style={{color:"white"}}>{header}</h4>
            ))}
          </div>
          {paginate(savedProfiles, currentPage, itemsPerPage).map((profile, index) => (
            <ProfileRow key={index} profile={profile} />
          ))}
        </div>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
      </div>
    </section>
  );
};

export default RecruiterTable;