import { useState } from 'react';
import styles from './profileButton.module.css';
import { FaRegUserCircle } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { BsChatDots } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {RootState} from 'project-name/Redux/Store';
import users  from "project-name/assets/users.png";



const ProfileButton = () => {
  const navigate = useNavigate();

  const [showProfile, setShowProfile] = useState(false);
  const profileDetail = useSelector((state: RootState) => state.adminProfile.profile?.[0] || {});
  console.log(profileDetail , "sds") ;


  const handleLogout = () => {
    // Clear any authentication tokens or user data from storage
    localStorage.removeItem('userName');
    sessionStorage.removeItem('persona');
    sessionStorage.removeItem('candidateName');

    // Redirect the user to the login page or home page
    navigate('/login'); // Adjust the path according to your routing setup
  };

  const handleNotificationClick = () => {
    setShowProfile(!showProfile);
    // Fetch notifications when bell icon is clicked

  };

  const handleCancelClick = () => {
    setShowProfile(false);
  };

  return (
    <div>
      <div className={styles.navbarItemsTools} onClick={handleNotificationClick}>
        {/* <img src={users} alt="" className={styles.navbarItemsToolsProfile} /> */}
        <img src={users} style={{width:"60px"}} alt="" />

      </div>

      {showProfile && (
        <div className={styles.profileDropdown}>
          <div className={styles.profileDiv}>
            <div><h4>{profileDetail.email}</h4></div>
            <div className={styles.cancelIcon} onClick={handleCancelClick}>X</div>
          </div>

          <Link to='/edit/admin-profile'> <div className={styles.profileContent}>
            <div><FaRegUserCircle className={styles.profileIcon}/></div>
            <div><span className={styles.profileName}>Update Profile</span></div>
          </div></Link>
         
          <div className={styles.profileContent}>
            <div><RiLogoutCircleRLine className={styles.profileIcon}/></div>
            <div onClick={handleLogout}><span className={styles.profileName}>Logout</span></div>
          </div>
          <div className={styles.profileContent}>
            <div><BsChatDots className={styles.profileIcon}/></div>
            <div><span className={styles.profileName}>Helpdesk</span></div>
          </div>
        
        </div>
      )}
    </div>
  );
};

export default ProfileButton;
