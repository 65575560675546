import Navbar from 'project-name/components/CandidateSecion/Global/Navbar/VerticalNavbar';
import styles from 'project-name/css/layout.module.css';
import Breadcrumb from 'project-name/components/CandidateSecion/Documents/Breadcrumb';
import FaqSection from 'project-name/components/CandidateSecion/Faq/FaqSection';

const Faq = () => {
  return (
    <div className={styles.mDiv}>
    <div>
        <Navbar/>
    </div>
    <div className={styles.secondDiv}>

      <div style={{margin:'40px 100px'}}><Breadcrumb bread1="Home" bread2="Faqs"  link1="/" />
</div>


      <FaqSection/>


     

    </div>

   
</div>
  )
}

export default Faq
